import React from "react";

import "./AppModal.css";
import AppOverlay from "./AppOverlay";
import { CloseIcon } from '../UI_utils/SVGIcons'

const AppModal = (
    {
        className,
        icon: Icon,
        buttonIcon: ButtonIcon,
        title, text,
        buttonText,
        buttonAction,
        secondaryButtonText,
        secondaryButtonAction,
        onCloseAction,
        height,
        width,
        padding,
        children,
        infoText
    }) => {
  return (
      <div className={`app-modal ${className}`}>
        <AppOverlay open={true} />
          <div className="app-modal__content" style={{height: `${height}px`, width: `${width}px`, padding: padding}}>
            {children ? children :
            <>
              <div className="app-modal__header">
                    <div className="app-modal-icon"><Icon /></div>
                    {onCloseAction &&
                      <CloseIcon onClick={onCloseAction}/>
                    }
                  </div>
                  <div className="app-modal-title" dangerouslySetInnerHTML={{ __html: title }} />
                  <div className="app-modal-text" dangerouslySetInnerHTML={{ __html: text }} />
                  {((buttonText && buttonAction) || (secondaryButtonText && secondaryButtonAction)) && (
                      <div className="app-modal__buttons-wrapper">
                          {secondaryButtonText && secondaryButtonAction && (
                              <div className="app-modal-button app-modal-button--secondary" onClick={secondaryButtonAction}>{secondaryButtonText}</div>
                          )}
                          {buttonText && buttonAction && (
                              <div className={`app-modal-button ${!secondaryButtonText || !secondaryButtonAction ? 'big-button' : ''}`} onClick={buttonAction}>
                                  {ButtonIcon && <ButtonIcon />}
                                  {buttonText}
                              </div>
                          )}
                      </div>
                  )}
                {infoText && (
                    <div className="app-modal__info-text" dangerouslySetInnerHTML={{ __html: infoText }} />
                )}
              </>
            }
        </div>
      </div>
  );
};

export default AppModal;
