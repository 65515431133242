import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './DefaultSettingsSidebarContentStyle.css'
import {
    getStyleFromUrl,
    getStyleNameAndType, loadingStyleName,
    setLoadingStyleName,
    setShowSettingsSidebar,
    updateAndSaveCampaignStyle
} from "../../reducers/articleSettings";
import AppSelect from "../../Shared/AppSelect";
import Input from "../../Shared/Input";
import {WhiteTooltipAuto} from "../UITooltips/UITooltip";
import {ConfigurationIcon, FileIcon, GlobeIcon, PenIcon, PlusCircle} from "../../UI_utils/SVGIcons";
import LoadingBlock from "../LoadingBlock/LoadingBlock";

const DefaultSettingsSidebarContentStyle = ({
    currentArticleId,
    savedStyleUrl,
    optionsToBeUsed,
    newStyleType,
    newStyleName,
    newStyleDescription,
    newStyleUrl,
    articleSampleUrl,
    addingArticleSampleUrl,
    setOptionsToBeUsed,
    setNewStyleType,
    setNewStyleName,
    setNewStyleDescription,
    setNewStyleUrl,
    setArticleSampleUrl,
    setAddingArticleSampleUrl,
}) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const loadingName = useSelector(loadingStyleName);

    const WRITER_TAB_INFO = {
        selected: {
            icon: ConfigurationIcon,
            iconTooltip: 'Based on the selected settings.'
        },
        article: {
            icon: FileIcon,
            iconTooltip: 'Based on analyzing article sample.'
        },
        website: {
            icon: GlobeIcon,
            iconTooltip: 'Based on analyzing website.'
        }
    }

    const IconComponent = WRITER_TAB_INFO[newStyleType]?.icon;
    const returnInfoTooltip = () => WRITER_TAB_INFO[newStyleType]?.iconTooltip


    // Create a state for each select component based on its key
    const initialSelections = optionsToBeUsed.reduce((acc, config) => {
        acc[config.key] = {
            value: config.selected[0],
            label: config.selected[0]
        };
        return acc;
    }, {});

    const [selectedValues, setSelectedValues] = useState(initialSelections);
    const handleChange = (key, selected) => {
        setSelectedValues((prev) => ({
            ...prev,
            [key]: selected,
        }));

        setOptionsToBeUsed((prevOptions) =>
            prevOptions.map((config) =>
                config.key === key
                    ? { ...config, selected: Array.isArray(selected) ? selected.map((item) => item.value) : [selected.value] }
                    : config
            )
        );

        setNewStyleType('selected');
    };

    const closeSidebar = () => dispatch(setShowSettingsSidebar(null))
    const saveSettings = () => {
        const payload = {
            writingStyles: optionsToBeUsed,
            url: newStyleUrl,
            styleName: newStyleName,
            styleType: newStyleType,
            styleDescription: newStyleDescription
        }
        dispatch(updateAndSaveCampaignStyle(payload))
    }

    const [sampleError, setSampleError] = useState('');

    const articleSampleUrlInputHandler = (newValue) => {
        setArticleSampleUrl(newValue)
        setSampleError('')
    }

    const [showEditIconForSample, setShowEditIconForSample] = useState(!!savedStyleUrl);

    const addArticleSampleUrl = () => {
        if(showEditIconForSample) {
            setShowEditIconForSample(false);
            setTimeout(() => inputRef.current?.focus())
            return ;
        }
        if(articleSampleUrl) {
            setAddingArticleSampleUrl(true);
            dispatch(setLoadingStyleName(true));

            dispatch(getStyleFromUrl(articleSampleUrl, currentArticleId))
                .then(response => {
                    const {writingStyles} = response;
                    if(writingStyles) {
                        setNewStyleType('article')
                        setNewStyleUrl(articleSampleUrl)
                        dispatch(getStyleNameAndType(writingStyles))
                            .then(response => {
                                const {styleName, styleDescription} = response;
                                if(styleName) setNewStyleName(styleName)
                                if(styleDescription) setNewStyleDescription(styleDescription)
                            })
                    } else {
                        const errorText = response.error || 'We couldn\'t reach the article. Check URL or provide another example.';
                        setSampleError(errorText)
                        dispatch(setLoadingStyleName(false));
                    }
                })
                .catch(err => {
                    const errorText = err.error || 'We couldn\'t reach the article. Check URL or provide another example.';
                    setSampleError(errorText)
                    dispatch(setLoadingStyleName(false));
                })
                .finally(() => {
                    setAddingArticleSampleUrl(false);
                })
        }
    };

    const returnIconToShow = () => {
        if(savedStyleUrl && !showEditIconForSample) return PlusCircle

        return PenIcon
    }

    return (
        <div className="writer-style">
            <div className="writer-style__name">
                {(loadingName && (
                    <LoadingBlock height="24" width="250" rounded={true}/>
                )) || (
                    <>
                        {IconComponent && (
                            <WhiteTooltipAuto placement="bottom-end" title={returnInfoTooltip()}>
                                <span><IconComponent /></span>
                            </WhiteTooltipAuto>
                        )}
                        <span>{newStyleName}</span>
                    </>
                )}
            </div>
            <div className="writer-style__options">
                {!addingArticleSampleUrl && optionsToBeUsed.map((config) => (
                    <AppSelect className={'grey'}
                        key={config.key}
                        label={config.label}
                        selectedValue={selectedValues[config.key]}
                        options={config.options.map((option) => ({
                            value: option,
                            label: option,
                        }))}
                        isMulti={config.multiselect}
                        onChange={(selected) => handleChange(config.key, selected)}
                    />
                ))}

                {addingArticleSampleUrl && optionsToBeUsed.map((config, index) => (
                    <LoadingBlock height="56" width="100%" key={`loading-${index}`}/>
                ))}

                <div className="writer-style__article-sample">
                    <div className="article-sample__label">Learn from example article</div>
                    <Input ref={inputRef}
                        type="text"
                        element="input"
                        label="Article URL"
                        placeholder="E.g. https://website.com/article-sample"
                        inputValue={articleSampleUrl}
                        showIcon={!!articleSampleUrl && !sampleError}
                        loading={addingArticleSampleUrl}
                        IconToShow={returnIconToShow()}
                        disabled={showEditIconForSample}
                        infoText={addingArticleSampleUrl && 'Processing example article. Usually takes up to 1 minutes.'}
                        error={sampleError}
                        onChange={articleSampleUrlInputHandler}
                        onEnter={addArticleSampleUrl}
                    />
                </div>
            </div>
            <div className="writer-style__actions">
                <div className="writer-style__button cancel" onClick={closeSidebar}>Cancel</div>
                <div className="writer-style__button save" onClick={saveSettings}>Save settings</div>
            </div>
        </div>
    );
};

export default DefaultSettingsSidebarContentStyle