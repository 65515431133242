/** @format */

import { createSlice } from '@reduxjs/toolkit';
import {
    getDashboard,
    getDashboardEndpoints,
    setNewDate,
    setNewOption,
    deleteArticleRow,
    getProjectsDashboard,
    requestWriters,
    requestProofreaders,
} from '../services/dashboard';
import { setApiError } from './apiErrors';
import { checkArticleIdAndTableRows } from "./articles";

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        allDashboardData: null,
        totalRows: null,
        industries: null,
        projects: null,
        userProjects: null,
        feedbacks: null,
        statuses: null,
        writers: null,
        proofreaders: null,
        noDataResults: false,
        projectsDashboardData: null,
        showAppLoading: false
    },
    reducers: {
        setShowAppLoading: (state, { payload }) => {
            return {
                ...state,
                showAppLoading: payload,
            };
        },
        setDashboardData: (state, { payload }) => {
            return {
                ...state,
                allDashboardData: payload,
            };
        },

        setTotalRows: (state, { payload }) => {
            return {
                ...state,
                totalRows: payload,
            };
        },

        setIndustries: (state, { payload }) => {
            return {
                ...state,
                industries: payload,
            };
        },

        setProjects: (state, { payload }) => {
            return {
                ...state,
                projects: payload,
            };
        },
        setUserProjects: (state, { payload }) => {
            return {
                ...state,
                userProjects: payload,
            };
        },

        setFeedbacks: (state, { payload }) => {
            return {
                ...state,
                feedbacks: payload,
            };
        },

        setStatuses: (state, { payload }) => {
            return {
                ...state,
                statuses: payload,
            };
        },

        setWriters: (state, { payload }) => {
            return {
                ...state,
                writers: payload,
            };
        },

        setProofreaders: (state, { payload }) => {
            return {
                ...state,
                proofreaders: payload,
            };
        },

        setNoDataResults: (state, { payload }) => {
            return {
                ...state,
                noDataResults: payload,
            };
        },

        setProjectsDashboardData: (state, { payload }) => {
            return {
                ...state,
                projectsDashboardData: payload,
            };
        },
    },
});

export const { setDashboardData } = dashboardSlice.actions;
export const { setShowAppLoading } = dashboardSlice.actions;
export const { setTotalRows } = dashboardSlice.actions;
export const { setNoDataResults } = dashboardSlice.actions;

// get all dashboard data on init

// articles dashboard data
export const getDashboardData = (currentPage, rowsPerPage, searchValue, filters, sortedBy) => async (dispatch) => {
    try {
        const { rows, total } = await getDashboard(currentPage, rowsPerPage, searchValue, filters, sortedBy);

        const noResults = rows && rows.some((row) => row.empty);

        dispatch(setNoDataResults(noResults));

        dispatch(setDashboardData(rows));

        if(!searchValue) dispatch(setTotalRows(total));

        dispatch(checkArticleIdAndTableRows());
    } catch (error) {
        dispatch(setApiError(error));
    }
};

// update current dashboard scores

export const updateArticleScores = ({id, articleScore, outlineScore}) => (dispatch, getState) => {
    const { allDashboardData } = getState().dashboard;
    let allArticles = JSON.parse(JSON.stringify(allDashboardData));
    if(allArticles){
         // get article by id;
        const articleIndex = allArticles.findIndex((article) => article.id == id);

        // set the article scores;
        allArticles[articleIndex].articleScore = articleScore;
        allArticles[articleIndex].outlineScore = outlineScore;

        dispatch(setDashboardData(allArticles));
    }
   
}

/* selectors */
export const dashboardData = ({ dashboard: { allDashboardData } }) => allDashboardData;
export const dashboardNoDataResults = ({ dashboard: { noDataResults } }) => noDataResults;
export const dashboardTotalRows = ({ dashboard: { totalRows } }) => totalRows;
export const showAppLoading = ({ dashboard: { showAppLoading } }) => showAppLoading;

export default dashboardSlice.reducer;
