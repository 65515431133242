import { createSelector, createSlice } from "@reduxjs/toolkit";

export const countersSlice = createSlice({
  name: "counters",
  initialState: {
    totalWords: 0,
    totalCharacters: 0,
    maxWords: 10,
  },
  reducers: {
    setWordCount: (state, { payload }) => {
      return {
        ...state,
        totalWords: payload,
      };
    },

    setMaxWordCount: (state, { payload }) => {
      return {
        ...state,
        maxWords: payload,
      };
    },

    setCharactersCount: (state, { payload }) => {
      return {
        ...state,
        totalCharacters: payload,
      };
    },
  },
});

// actions
export const { setWordCount } = countersSlice.actions;
export const { setMaxWordCount } = countersSlice.actions;

// selectors

export const getWordCount = ({ counters: { totalWords } }) => totalWords;

export const getMaxWordCount = ({ counters: { maxWords } }) => maxWords;

export const getUpdatedWordsPercentage = createSelector(
  [getWordCount, getMaxWordCount],
  (totalWords, maxWords) => {
    if(!isNaN(maxWords))
      return ((totalWords * 100) / maxWords) * 2

    const minValue = maxWords[0];

    if (totalWords <= minValue) {
      return (totalWords / minValue) * 100;
    }

    return 100;

  }
);

export default countersSlice.reducer;
