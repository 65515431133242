import React, {useEffect, useRef, useState} from 'react';
import './ModalStartArticle.css'
import {CrossIcon, SpinnerIcon, PlusIcon} from "../../UI_utils/SVGIcons";
import {interfaceApiRequest} from "../../utils/dataToInterface";
import {WhiteTooltip, WhiteTooltipAuto} from "../UITooltips/UITooltip";
import {useDispatch, useSelector} from "react-redux";
import {getSearchKeywords} from "../../reducers/searchTopic";
import {
    getSuggestedTopics,
    getUserAccessToNewTracking,
    getUserAccessToSuggestedTopics,
    getUserHasArticlesLeft
} from "../../reducers/user";
import {TEXTS} from "../../common/consts";
import {showTopicSessionForNewArticle} from "../../reducers/confirmationModal";

const ModalStartArticle = ({userCanEdit, setProcessingTopic}) => {
    const DEFAULT_ERROR = 'Keywords is not searched with informational intent. Try different keyword<br />or use other options.';

    const dispatch = useDispatch();

    const hasArticlesLeft = useSelector(getUserHasArticlesLeft);
    const newTrackingAccess = useSelector(getUserAccessToNewTracking);
    const suggestedTopics = useSelector(getSuggestedTopics);
    const suggestedTopicsAccess = useSelector(getUserAccessToSuggestedTopics);

    const errorRef = useRef(null);

    const [newArticleTopic, setNewArticleTopic] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [customError, setCustomError] = useState('');

    const returnError = () => customError || DEFAULT_ERROR

    const handleInputChange = (event) => {
        setError(false);
        setNewArticleTopic(event.target.value);
    }

    const componentStateClassName = () => {
        let dynamicClass = '';

        if(loading) dynamicClass += 'modal-start-article--loading '
        if(!!newArticleTopic) dynamicClass += 'modal-start-article--active '
        if(error) dynamicClass += 'modal-start-article--error '
        if(!userCanEdit) dynamicClass += 'modal-start-article--disabled '
        if(!hasArticlesLeft) dynamicClass += 'modal-start-article--no-articles-left '

        return dynamicClass
    }

    const checkTopicAndStartArticle = () => {
        if(!loading && !error && userCanEdit && hasArticlesLeft) {
            if(newArticleTopic.split(',').length > 1) {
                setCustomError('Please insert only one keyword.')
                return setError(true);
            }

            let payload;
            const topic = newArticleTopic.toLowerCase();

            setLoading(true)
            setProcessingTopic(topic)

            if(newTrackingAccess) {
                payload = {
                    keywords: [topic],
                    trackKeywordsFromWriter: [topic],
                    topic: topic,
                    newTopicFromWriter: true,
                    openInterstitialModal: true,
                    getTopicInfo: true,
                };
            } else {
                payload = {
                    trackKeywordsFromWriter: [topic],
                    newTopicFromWriter: true,
                    old: true
                };
            }

            interfaceApiRequest('checkTopicAndStartArticleMessage', payload)
                .then(resp => {
                    if(resp.message) {
                        setCustomError(resp.message)
                        setError(true);
                    } else if (resp.waitingForTopicInfo) {
                        dispatch(showTopicSessionForNewArticle(resp))
                    }
                })
                .catch(err => {
                    setCustomError(err.message)
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                    setProcessingTopic('')
                })
        }
    }

    useEffect(() => {
        const handleLinkClick = (e) => {
            if (e.target.tagName === 'A') {
                e.preventDefault();
                window.parent.postMessage({ goToUrl: e.target.href }, '*');
            }
        };

        if (errorRef.current) {
            errorRef.current.addEventListener('click', handleLinkClick);
        }

        // Cleanup
        return () => {
            if (errorRef.current) {
                errorRef.current.removeEventListener('click', handleLinkClick);
            }
        };
    }, [errorRef.current]);

    const enterHandler = (event) => {
        if (event.key === 'Enter') checkTopicAndStartArticle()
    }

    const placeholder = () => {
        if(!suggestedTopicsAccess) return 'E.g. How to dry clothes quickly';
        if(!suggestedTopics || !suggestedTopics.length) return ''

        return `E.g. ${suggestedTopics[0].topic}`
    }


    return (
        <WhiteTooltip
            placement="bottom-start"
            title={userCanEdit ? '' : 'You don’t have access to this.'}
        >
            <div className={`modal-start-article ${componentStateClassName()}`}>
                <div className="start-article__label">What topic would you like to rank for?</div>

                <div className="start-article__input-wrapper">
                    <input
                        type="text"
                        value={newArticleTopic}
                        onChange={handleInputChange}
                        onKeyDown={enterHandler}
                        className={`start-article__input`}
                        placeholder={placeholder()}
                        disabled={!userCanEdit}
                    />

                    <WhiteTooltipAuto
                        placement="bottom-end"
                        title={hasArticlesLeft ? '' : TEXTS.NO_ARTICLES_LEFT}
                    >
                        <div className="start-article__input-action" onClick={checkTopicAndStartArticle}>
                            {!!newArticleTopic && !loading && !error && (
                                <PlusIcon />
                            )}
                            {loading && (
                                <SpinnerIcon />
                            )}
                        </div>
                    </WhiteTooltipAuto>
                </div>
                {error && !loading && (
                    <div className="start-article__error"
                         ref={errorRef}
                         dangerouslySetInnerHTML={{
                             __html: returnError(),
                         }}
                    />
                )}
                {loading && !error && (
                    <div className="start-article__loading-info">Processing keyword. Usually takes up to 1 minute.</div>
                )}
            </div>
        </WhiteTooltip>
    );
};

export default ModalStartArticle