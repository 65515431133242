import { createSlice } from "@reduxjs/toolkit";

export const categoryTextEditorSlice = createSlice({
  name: "categorytexteditor",
  initialState: {
    categoryEditorData: "",
    metaTitle: "",
    metaDescription: "",
    h1Tag: "",
    parsedMetaData: {
      headings1: "",
      paragraphs: "",
      headings: "",
    },
    isDataLoaded: false,
  },

  reducers: {
    setCategoryEditorData: (state, { payload }) => {
      return {
        ...state,
        categoryEditorData: payload,
      };
    },
    setMetaTitle: (state, { payload }) => {
      return {
        ...state,
        metaTitle: payload,
      };
    },

    setMetaDescription: (state, { payload }) => {
      return {
        ...state,
        metaDescription: payload,
      };
    },

    setH1Tag: (state, { payload }) => {
      return {
        ...state,
        h1Tag: payload,
      };
    },

    setDescriptionDataLoaded: (state, { payload }) => {
      return {
        ...state,
        isDataLoaded: payload,
      };
    },

    setParsedMetaData: (state, { payload }) => {
      return {
        ...state,
        parsedMetaData: payload,
      };
    },
  },
});

// actions
export const { setDescriptionDataLoaded } = categoryTextEditorSlice.actions;

export default categoryTextEditorSlice.reducer;
