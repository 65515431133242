import React, {useRef , useEffect, useState} from 'react';
import './saveOptions.css';
import { useSelector,  useDispatch } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import {exportArticleToPdf, getShareHash} from '../../reducers/articles';
import { getEditorInstance } from '../../reducers/textEditor';
import Popup from 'reactjs-popup';
import {
    setGenericConfirmationText,
    setShowGenericConfirmationModal,
    setOncloseHandler,
    setSecondaryGenericConfirmationHandler
} from "../../reducers/confirmationModal";



const SaveOptions = ({ setDownloadOptions, writerMode }) => {
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    const editor = useSelector(getEditorInstance);
    const shareHash = useSelector(getShareHash);
    const [popupOpen, setPopupOpen] = useState(false);


    const handleOutsideClick = (event) => {
        event.stopPropagation()
    if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
    ) {
            setDownloadOptions(false);
        }
    };

    const showConfirmationModal = (type = 'html') => {
        const bodyContent = {
            html: {
                title: 'Copied to clipboard',
                description: 'HTML code of the article has been copied to clipboard.',
            },
            link: {
                title: 'Link copied.',
                description: 'Share this link with the person who needs to edit the article. They will have full edit access to this article only.',
            }
        }

        dispatch(setGenericConfirmationText({
            title: bodyContent[type].title,
            description: bodyContent[type].description,
            secondaryButtonText: 'Got it'
        }))

        dispatch(setOncloseHandler(() => dispatch(setShowGenericConfirmationModal(false))))
        dispatch(setSecondaryGenericConfirmationHandler(() => {
            dispatch(setShowGenericConfirmationModal(false))
            dispatch(setGenericConfirmationText(''))
        }))
        dispatch(setShowGenericConfirmationModal(true));
    }

    const displayHtmlNotification = () => showConfirmationModal('html');
    const displayShareableLinkNotification = () => showConfirmationModal('link');

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

     const exportArticle = () => {
         dispatch(exportArticleToPdf(writerMode));
     };
    
    
    const openPopup = () => setPopupOpen(true);
    const closePopup = () => setPopupOpen(false);
    const shareableLink = () => {
        const hash = shareHash;

        return `${window.location.origin}/shared-article/${hash}`
    }
    return (
        <>
            <Popup open={popupOpen} onClose={closePopup} closeOnDocumentClick={true}>
                <div className="notification-modal">
                    <p className="notification-message">{'The sourcecode of the article has been copied to clipboard'}</p>
                </div>
            </Popup>
            <div className="save-options-container" ref={menuRef}>
                <div className="option-item" onClick={exportArticle}>
                    Save as PDF
                </div>
                <CopyToClipboard text={editor?.getHTML()} onCopy={displayHtmlNotification}>
                    <div className="option-item">Copy HTML</div>
                </CopyToClipboard>
                {!!shareHash && (
                    <CopyToClipboard text={shareableLink()} onCopy={displayShareableLinkNotification}>
                        <div className="option-item">Copy shareable link</div>
                    </CopyToClipboard>
                )}
            </div>
        </>
    );
};

export default SaveOptions