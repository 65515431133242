import React, {useEffect, useRef, useState} from 'react';
import './DefaultSettingsSidebar.css'

import AppOverlay from "../../Shared/AppOverlay";
import DefaultSettingsSidebarHeader from "./DefaultSettingsSidebarHeader";
import {useDispatch, useSelector} from "react-redux";
import DefaultSettingsSidebarContentPrompt from "./DefaultSettingsSidebarContentPrompt";
import {
    customCampaignStyle,
    defaultStyleOptions, getStyleNameAndType,
    setShowSettingsSidebar,
    showSettingsSidebar, styleDescription, styleName, styleType, styleUrl
} from "../../reducers/articleSettings";
import DefaultSettingsSidebarSubHeader from "./DefaultSettingsSidebarSubHeader";
import AppTabSelector from "../../Shared/AppTabSelector";
import DefaultSettingsSidebarContentStyle from "./DefaultSettingsSidebarContentStyle";
import {BookIcon, ChatIcon, ConfigurationIcon, FileIcon, GlobeIcon, MagicIcon, SaveIcon} from "../../UI_utils/SVGIcons";
import {getUserHasWritingStyles} from "../../reducers/user";
import {getCurrentArticleId} from "../../reducers/articles";

const DefaultSettingsSidebar = ({}) => {

    const dispatch = useDispatch();
    const showSidebarType = useSelector(showSettingsSidebar)
    const styleAccess = useSelector(getUserHasWritingStyles)
    const setSettingsContent = (type) => dispatch(setShowSettingsSidebar(type));
    const close = () => setSettingsContent(null)

    const [settingsOptions, setSettingsOptions] = useState([
        {
            label: 'Writing style',
            key:'style',

        }, {
            label: 'Campaign brief',
            key: 'prompt',
        }])

    /**
     * Article Style
     */

    const currentArticleId = useSelector(getCurrentArticleId);
    const defaultOptions = useSelector(defaultStyleOptions);
    const campaignStyle = useSelector(customCampaignStyle)
    const savedStyleUrl = useSelector(styleUrl)
    const savedStyleType = useSelector(styleType)
    const savedStyleName = useSelector(styleName)
    const savedStyleDescription = useSelector(styleDescription)
    const [optionsToBeUsed, setOptionsToBeUsed] = useState(campaignStyle || defaultOptions)

    const [newStyleType, setNewStyleType] = useState(savedStyleType);
    const [newStyleName, setNewStyleName] = useState(savedStyleName);
    const [newStyleDescription, setNewStyleDescription] = useState(savedStyleDescription);
    const [newStyleUrl, setNewStyleUrl] = useState(savedStyleUrl);
    const [articleSampleUrl, setArticleSampleUrl] = useState(savedStyleUrl);
    const [addingArticleSampleUrl, setAddingArticleSampleUrl] = useState(false);
    const isInitialRender = useRef(true);


    useEffect(() => {
        if(!isInitialRender.current) {
            dispatch(getStyleNameAndType(optionsToBeUsed))
                .then(({styleName, styleDescription}) => {
                    if(styleName) setNewStyleName(styleName)
                    if(styleDescription) setNewStyleDescription(styleDescription)
                })
        }

        isInitialRender.current = false;
    }, [optionsToBeUsed])

    return (
        <>
            {!!showSidebarType && (
                <div className="default-settings-sidebar__wrapper">
                    <AppOverlay open={true} close={close} />

                    <div className="default-settings-sidebar">
                        <DefaultSettingsSidebarHeader close={close} />
                        {styleAccess && (
                            <DefaultSettingsSidebarSubHeader
                                selectedOption={showSidebarType}
                                options={settingsOptions}
                                onSwitch={setSettingsContent}
                            />
                        )}
                        <div className="default-settings-sidebar__content">
                            {showSidebarType === 'prompt' && <DefaultSettingsSidebarContentPrompt/>}
                            {showSidebarType === 'style' && (
                                <DefaultSettingsSidebarContentStyle
                                    currentArticleId={currentArticleId}
                                    savedStyleUrl={savedStyleUrl}
                                    optionsToBeUsed={optionsToBeUsed}
                                    newStyleType={newStyleType}
                                    newStyleName={newStyleName}
                                    newStyleDescription={newStyleDescription}
                                    newStyleUrl={newStyleUrl}
                                    articleSampleUrl={articleSampleUrl}
                                    addingArticleSampleUrl={addingArticleSampleUrl}
                                    setOptionsToBeUsed={setOptionsToBeUsed}
                                    setNewStyleType={setNewStyleType}
                                    setNewStyleName={setNewStyleName}
                                    setNewStyleDescription={setNewStyleDescription}
                                    setNewStyleUrl={setNewStyleUrl}
                                    setArticleSampleUrl={setArticleSampleUrl}
                                    setAddingArticleSampleUrl={setAddingArticleSampleUrl}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DefaultSettingsSidebar