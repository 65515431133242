import { createSlice } from "@reduxjs/toolkit";
import {shortenNumbers} from "../utils/helpers";
import {ThinCheckIcon, WriterMagicPen} from "../UI_utils/SVGIcons";
import {setShowAppLoading} from "./dashboard";

export const confirmationModal = createSlice({
  name: "confirmationmodal",
  initialState: {
    showGenericConfirmationModal: false,
    genericConfirmationText: '',
    genericConfirmationHandler: null,
    secondaryGenericConfirmationHandler: null,
    onCloseGenericConfirmationHandler: null
  },
  reducers: {
    setShowGenericConfirmationModal: (state, { payload }) => {
      return {
        ...state,
        showGenericConfirmationModal: payload,
      };
    },
    setGenericConfirmationText: (state, { payload }) => {
      return {
        ...state,
        genericConfirmationText: payload,
      };
    },
    setGenericConfirmationHandler: (state, { payload }) => {
      return {
        ...state,
        genericConfirmationHandler: payload,
      };
    },
    setSecondaryGenericConfirmationHandler: (state, { payload }) => {
      return {
        ...state,
        secondaryGenericConfirmationHandler: payload
      }
    },
    setOncloseHandler: (state, {payload}) => {
      return {
        ...state,
        onCloseGenericConfirmationHandler: payload
      }
    }
  },
});

// actions
export const { setShowGenericConfirmationModal } = confirmationModal.actions;
export const { setGenericConfirmationText } = confirmationModal.actions;
export const { setGenericConfirmationHandler } = confirmationModal.actions;
export const { setSecondaryGenericConfirmationHandler } = confirmationModal.actions
export const { setOncloseHandler } = confirmationModal.actions

export const showTopicSessionForNewArticle = (data) => (dispatch, getState) => {
  const title = `${shortenNumbers(data.sessions)} monthly organic visits projected for this topic`
  const description = `<strong>${data.consumed}</strong> article generation will be consumed, <strong>${data.available}</strong> more available.`
  const icon = ThinCheckIcon;
  const buttonIcon = WriterMagicPen;
  const buttonText = `Create article`
  const secondaryButtonText = 'Try another keyword'
  dispatch(setGenericConfirmationHandler( () => {
    dispatch(setShowAppLoading(true))
    window.parent.postMessage({storeDispatch: 'manageStrategyStore/writeArticleFromInterstial'}, '*');
  }))
  dispatch(setSecondaryGenericConfirmationHandler( () => dispatch(setShowGenericConfirmationModal(false))))

  dispatch(setGenericConfirmationText({title, description, icon, buttonIcon, buttonText, secondaryButtonText}))

  dispatch(setShowGenericConfirmationModal(true));

}
// selectors
export const showConfirmationModal = ({ confirmationModal: { showGenericConfirmationModal } }) => showGenericConfirmationModal;
export const getGenericConfirmationText = ({ confirmationModal: { genericConfirmationText } }) => genericConfirmationText;
export const getGenericConfirmationHandler = ({ confirmationModal: { genericConfirmationHandler } }) => genericConfirmationHandler;
export const getSecondaryConfirmationHandler = ({ confirmationModal : {secondaryGenericConfirmationHandler}}) => secondaryGenericConfirmationHandler
export const getOncloseHandler = ({ confirmationModal: { onCloseGenericConfirmationHandler }}) => onCloseGenericConfirmationHandler
export default confirmationModal.reducer;
