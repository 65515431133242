import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import "./index.css";
import { REACT_APP_SENTRY_DSN} from './common/consts';
const deniedUrls = [
  /https:\/\/rs\.fullstory\.com\/.*/,
  // /https:\/\/edge\.fullstory\.com\/.*/,
  // /https:\/\/api\.segment\.io\/.*/,
  // /https:\/\/cdn\.segment\.com\/.*/,
  // /https:\/\/px\.ads\.linkedin\.com\/.*/,
  // /https:\/\/client-api\.auryc\.com\/.*/,
  // /https:\/\/www2\.profitwell\.com\/.*/,
  // /https:\/\/[^.]+\.statuspage\.io\/.*/,
  // /https:\/\/cdn\.googleadservices\.com\/.*/,
  // /https:\/\/cdn\.googletagmanager\.com\/.*/,
  // /https:\/\/blocks\.dopt\.com\/.*/,
  // /https:\/\/users\.dopt\.com\/.*/,
  // /https:\/\/cdn\.heapanalytics\.com\/.*/,
];
if(process.env.NODE_ENV !== 'development') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.browserTracingIntegration(),
      new Sentry.getReplay(),
      Sentry.replayIntegration({
        maskAllText: false
      }),
      Sentry.httpClientIntegration()
    ],
    tracePropagationTargets: ["localhost", "writer.seomonitor.ro", "writer.seomonitor.com", /^\//],
    denyUrls: deniedUrls,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
