import React from 'react';
import AppSwitcher from "../../../Shared/AppSwitcher";
import {getCurrentArticleId, subscribingUser, userSubscribed} from "../../../reducers/articles";
import {useDispatch, useSelector} from "react-redux";
import {getUserAccessToNotifyMe} from "../../../reducers/user";

const AiCardOptionsStopGeneratingArticle = ({
    disableStopGenerating,
    stopGenerating,
}) => {
    const dispatch = useDispatch();
    const subscribed = useSelector(userSubscribed);
    const hasNotifyMeOption = useSelector(getUserAccessToNotifyMe)
    const currentArticleId = useSelector(getCurrentArticleId);

    const subscribeUser = (value) => {
        const payload = {
            id: currentArticleId,
            notify_email: value
        }
        dispatch(subscribingUser(payload));
    }

    return (
        <div className="ai-card-buttons ai-card-buttons--subscribe">
            {hasNotifyMeOption && (
                <AppSwitcher
                    inputId={'subscribe'}
                    label="Email me when ready"
                    isChecked={subscribed}
                    updateState={subscribeUser}
                />
            )}
            <div
                className={`ai-card-button ai-card-button--stop ${disableStopGenerating ? 'disabled' : ''}`}
                onClick={stopGenerating}>
                Stop generating
            </div>
        </div>
    );
};

export default AiCardOptionsStopGeneratingArticle;