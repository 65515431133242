import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ArticleMode from "../ApplicationMode/ArticleMode";
import useMount from "../utils/useMount";
import {
  DASHBOARD_PATH,
  DEFAULT_ARTICLE_ID,
  OPEN_MODAL_INTERVAL,
} from "../common/consts";
import {getCurrentArticleId, getStopWordsForArticle, requestArticleById} from "../reducers/articles";
import { isDemoApplication } from "../reducers/applicationMode";

import "./HomeContent.css";
import {setGlobalState} from "../globals";

const HomeContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const articleId = useSelector(getCurrentArticleId);
  const isDemoApp = useSelector(isDemoApplication);

  const { id, sharedToken } = useParams();

  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const openModalHandler = () => setShowModal(true);

  const hideModal = id && id !== DEFAULT_ARTICLE_ID;

  useMount(() => {
    // fetch article by id from url when we open the link or refresh the page
    // we do this only if id is not the default id, meaning 1
    if (!articleId && hideModal) {
      dispatch(requestArticleById(id));
      dispatch(getStopWordsForArticle(id));
    } else if(!!sharedToken) {
      setGlobalState('sharedToken', sharedToken);
      dispatch(requestArticleById(null, sharedToken));
    }

  });

  const forceCloseModal = () => {
    closeModalHandler();
    history.push(DASHBOARD_PATH);
  };

  return (
    <Fragment>
      <div className="homepage">
        <div className="homepage-wrapper">
          {/*<Header />*/}
          <ArticleMode />
        </div>
      </div>
    </Fragment>
  );
};

export default HomeContent;
