import React, {useEffect} from 'react';

import './Login.css';
import {checkToken, syncUser} from "../api/user";
import {DASHBOARD_PATH} from "../common/consts";
import {useHistory} from "react-router-dom";
import {setUserToken} from "../utils/userStatus";
import AppLoadingScreen from "../Shared/AppLoadingScreen";

const Login = () => {
    const history = useHistory();

    const checkTokenAndRedirect = async () => {
        const token = await checkToken();

        syncUser()

        if (token?.token) {
            setUserToken(token.token)
            history.push(DASHBOARD_PATH);
        }
    };

    useEffect (() => {
        checkTokenAndRedirect();
    });

  return <AppLoadingScreen />
};

export default Login;