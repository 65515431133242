import React from "react";
import DashboardComponent from "../Components/ManagementDashboard/DashboardComponent";

const Dashboard = () => {
  return (
          <DashboardComponent />
  );
};

export default Dashboard;
