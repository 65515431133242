import {
  getDashboardTable,
  dashboardInitData,
  updateDate,
  updateDrowpdownMenuOptions,
  deleteDasboardRow,
  createNewDashboardTopic,
  renameTopicTitle,
  sendLiveUrl,
  getProofreaders,
  getWriters,
} from "../api/dasboard";
import { getTopicData, getTopicCoverage } from "../api/topic-search";
import { getUserToken } from "../utils/userStatus";
import { ASSIGN, UNASSIGN } from "../common/consts";
import { transformAllData } from "./dashboard-helpers";
import { formatDateForServer } from "../utils/dateUtility";
import {
  getDashboardProjects,
  onAddNewProject,
  onDeleteProject,
} from "../api/projects";
import {getGlobalState} from "../globals";

export const getDashboardEndpoints = async (token) => {
  const data = await dashboardInitData(token);
  const { statuses } = data;

  // change text color to red if status is `Re-assigned to proof` or `Implemeting feedback
  const newStatuses = statuses.map((status) => ({
    ...status,
    color: status.id === 4 || status.id === 5,
  }));

  const newData = {
    ...data,
    statuses: newStatuses,
  };

  return newData;
};

export const requestWriters = async (token) => {
  const writers = await getWriters(token);
  return writers;
};

export const requestProofreaders = async (token) => {
  const proofs = await getProofreaders(token);
  return proofs;
};

// date picker updates
export const setNewDate = async (updateType, updatedRows) => {
  const token = getUserToken();
  const mappedIds = updatedRows.map((article) => article.id);
  const patchObject = {
    ids: mappedIds,
    // format date to be in MM-dd-yyyy shape, this is how server needs to receive it
    [updateType]: formatDateForServer(updatedRows[0][updateType]),
  };

  const data = await updateDate(token, updateType, patchObject);
  return data;
};

// drowpdown menu updates
// this works the same for status/project/feedback/industry
// `eventType` will be different for each one, being also the api path
export const setNewOption = async (eventType, updatedRows, updatedProperty) => {
  const token = getUserToken();
  const mappedIds = updatedRows.map((article) => article.id);

  // when changing single or bulk, we will always have the same value updated,
  // so we can use the first element in the array to read/send that updated property
  const selectedPropertyValue = updatedRows[0][updatedProperty];
  const previousPropertyValue = updatedRows[0][`prev${updatedProperty}`];

  const selectedUser = selectedPropertyValue || previousPropertyValue;
  // this payload is for writer/proofreader only
  const usersPayload = {
    ids: {
      [selectedUser]: mappedIds,
    },
  };

  // this payload is for assign categories
  const genericAssignPayload = {
    ids: {
      [mappedIds]: selectedPropertyValue,
    },
  };

  // // this payload is for unassign categories
  const genericUnassignPayload = {
    ids: mappedIds,
  };

  const genericPayload = selectedPropertyValue
    ? genericAssignPayload
    : genericUnassignPayload;

  const endpointType = selectedPropertyValue
    ? `${ASSIGN}-${eventType}`
    : `${UNASSIGN}-${eventType}`;

  // // patch object is different if we are updating the option for writers or proofreaders
  const patchObject =
    eventType === "users" || eventType === "status"
      ? usersPayload
      : genericPayload;

  const data = await updateDrowpdownMenuOptions(
    token,
    endpointType,
    patchObject
  );
  return data;
};

// create new dashboard entry

// remove row
export const deleteArticleRow = async (rowsId) => {
  const token = getUserToken();
  const onlyIds = rowsId.map(({ id }) => id);
  const articleIds = { ids: onlyIds };

  const confirmation = await deleteDasboardRow(token, articleIds);
  return confirmation;
};

// rename topic
export const renameTopic = async (rowId, newValue) => {
  const token = getUserToken();

  const payload = {
    id: rowId,
    name: newValue.value,
  };

  try {
    const renamedConfirm = await renameTopicTitle(token, payload);

    const dataTransform = {
      ...renamedConfirm,
      value: renamedConfirm.name,
    };

    return dataTransform;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

// pre-fetch topic data

export const getDashboard = async (
  currentPage,
  rowsPerPage,
  searchValue,
  filters,
  sortedBy
) => {
  const token = getUserToken();

  const payload = {
    page: currentPage,
    limit: rowsPerPage,
    search: searchValue || null,
    filters,
    sort: sortedBy ? { [sortedBy.name]: sortedBy.sortDirection } : null,
  };

  const data = await getDashboardTable(token, payload);

  if (data.rows) {
    const transformed = transformAllData(data);

    return transformed;
  } else {
    // we send empty flag in case of no results after filtering so we can keep the table head active, otherwise it will
    // become inactive and we cannot remove the filter
    return { rows: [{ empty: true }], total: data.total };
  }
};

export const getProjectsDashboard = async (
  currentPage,
  rowsPerPage,
  searchValue,
  filters,
  sortedBy
) => {
  const token = getUserToken();
  const payload = {
    page: currentPage,
    limit: rowsPerPage,
    search: searchValue || null,
    filters,
    sort: sortedBy ? { [sortedBy.name]: sortedBy.sortDirection } : null,
  };

  const data = await getDashboardProjects(token, payload);

  return data;
};

