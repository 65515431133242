import React, {useEffect, useState} from "react";

import { useSelector } from "react-redux";
import CompetitionItem from "./CompetitionItem";
import {
  getCompetitionData,
  loadingCompetitionData,
  isFetchCompetitionError, getAnotherMarketCompetitionData,
} from "../reducers/searchTopic";
import { getMaxImages } from "../reducers/images";
import CompetitionSkeletonPlaceholder from "./CompetitionSkeletonPlaceholder";

import "./Competition.css";
import Scrollbars from "react-custom-scrollbars";
import {CrossIcon} from "../UI_utils/SVGIcons";
import AppOverlay from "../Shared/AppOverlay";
import DefaultSettingsSidebarSubHeader from "./DefaultSettingsSidebar/DefaultSettingsSidebarSubHeader";
import AppTabSelector from "../Shared/AppTabSelector";
import {customStyleIcon} from "../reducers/articleSettings";

const Competition = ({ closeCompetition }) => {
  const [open, setOpen] = useState(false);
  const [competitionData, setCompetitionData] = useState();
  const competition = useSelector(getCompetitionData);
  const anotherMarketCompetition = useSelector(getAnotherMarketCompetitionData);
  const maxImages = useSelector(getMaxImages);
  const isLoadingCompetition = useSelector(loadingCompetitionData);
  const competitionError = useSelector(isFetchCompetitionError);

  useEffect(() => {
    setCompetitionData(competition);
  }, [competition]);

  useEffect(() => setOpen(true), [])

  const isArticle = true;
  const showContent = competitionData && !isLoadingCompetition;

  const transitionAndCloseSidebar = () => {
    setOpen(false)
    setTimeout(() => closeCompetition(), 500)
  }

  const [selectedOption, setSelectedOption] = useState('campaign')
  const [settingsOptions, setSettingsOptions] = useState([
    {
      label: 'Campaign market',
      key:'campaign',
      info: ''

    }, {
      label: 'Source market',
      key: 'source',
      info: ''
    }])

  useEffect(() => {
    if(anotherMarketCompetition && competition) {
      setSettingsOptions(prevOptions =>
          prevOptions.map(option =>
              option.key === 'campaign'
                  ? { ...option, info: competition.market || ''}
                  : { ...option, info: anotherMarketCompetition.market || ''}
          )
      );
    }
  }, [competition, anotherMarketCompetition]);

  const onSwitch = (data) => {
    setSelectedOption(data)
  }

  useEffect(() => {
    if(!!anotherMarketCompetition) setSelectedOption('source')
  }, [anotherMarketCompetition])

  useEffect(() => {
    if(selectedOption === 'campaign') setCompetitionData(competition);
    else setCompetitionData(anotherMarketCompetition);
  }, [selectedOption])

  const avgWords = () => {
    if(!competitionData || !competitionData.avgWords) return 0

    const count = competitionData.avgWords;

    if(!isNaN(count)) return count;


    return `${count[0]}-${count[1]}`
  }

  return (
    <>
      <AppOverlay open={open} close={transitionAndCloseSidebar}/>
      <div className={`competition ${open ? 'open' : ''}`}>
      {showContent && !competitionError && (
        <div className="competiton-head">
          <div className="competition-close-icon" onClick={transitionAndCloseSidebar}>
            <CrossIcon />
          </div>
          <h3>Competition Analysis</h3>
        </div>
      )}
      <Scrollbars>
        <div className="competition-content">
          <p className="competition-sub">
            An overview of top 10 articles ranking in Google for this topic
          </p>

          {showContent && isArticle && (
              <div className="top-numbers">
                <div className="competition-numbers info-words">
                  {avgWords()}
                  <div className="competition-numbers-info">
                    <div className="competition-numbers-label">Average-max wordcount</div>
                  </div>
                </div>
                <div className="competition-numbers info-images">
                  {maxImages.toLocaleString()}
                  <div className="competition-numbers-info">
                    <div className="competition-numbers-label">Average images</div>
                  </div>
                </div>
              </div>
          )}

          {isLoadingCompetition && (
            <CompetitionSkeletonPlaceholder isArticle={isArticle} />
          )}

          {competitionError ? (
            <div>
              Competition Data is not available currently
            </div>
          ) : (
            <>

              {anotherMarketCompetition && (
                  <AppTabSelector
                    selectedOption={selectedOption}
                    options={settingsOptions}
                    onSwitch={onSwitch}
                  />
              )}
              {competitionData &&
                competitionData.competitionList &&
                competitionData.competitionList.map(({ id, domain, ...rest }) => (
                  <CompetitionItem key={id + domain} {...rest} domain={domain} />
                ))}
            </>
          )}
        </div>
      </Scrollbars>
    </div>
    </>
  );
};

export default Competition;
