import React, {useState} from "react";

import "./EditorContentInfo.css";
import {
    styleUsedForGenerating,
} from "../../reducers/articleSettings";
import {useSelector} from "react-redux";
import WritingStyleOverview from "./AiCard/WritingStyleOverview";
import {
    BookIcon,
    ChatIcon,
    ChevronDown, ChevronUp,
    ConfigurationIcon,
    FileIcon,
    GlobeIcon,
    MagicIcon,
    UserIcon
} from "../../UI_utils/SVGIcons";

const EditorContentInfo = ({}) => {
    const styleConfigForGeneratedArticle = useSelector(styleUsedForGenerating);

    const returnIcon = () => {
        if (!styleConfigForGeneratedArticle) return MagicIcon;

        const {
            styleName: title,
            styleType: type
        } = styleConfigForGeneratedArticle;

        if(type)
            if(type === 'selected') return ConfigurationIcon
            if(type === 'article') return FileIcon
            if(type === 'website') return GlobeIcon

        if(title) {

            const loweredTitle = title.toLowerCase();

            if(loweredTitle === 'professional style') return UserIcon
            if(loweredTitle === 'educational style') return BookIcon
            if(loweredTitle === 'casual style') return ChatIcon
        }

        return MagicIcon
    }

    const [styleExpanded, setStyleExpanded] = useState(false)

    if(!styleConfigForGeneratedArticle) return <div></div>

    const {
        writingStyles: style,
        styleName: title,
        styleType: type,
        styleDescription: description,
    } = styleConfigForGeneratedArticle;

    return (
        <div className="editor-content-info">
            <div className="editor-content-info__header">
                <div></div>
                <div className="toggle-styling" onClick={() => setStyleExpanded(!styleExpanded)}>
                    {title}
                    {!styleExpanded && <ChevronDown/>}
                    {styleExpanded && <ChevronUp/>}
                </div>
            </div>
            {style && styleExpanded && (
                <WritingStyleOverview
                    isCustom={!!type}
                    styleType={type}
                    Icon={returnIcon()}
                    title={title}
                    description={description}
                    readOnly={true}
                    style={style}
                    type={'white'}
                />
            )}
        </div>
    );
};

export default EditorContentInfo;
