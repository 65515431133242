import React, {useEffect, useRef, useState} from 'react';
import './PromptPlaceholder.css'
// import {CrossIcon} from "../../UI_utils/SVGIcons";

const PromptPlaceholder = ({ close = () => void(0)}) => {

    return (
        <div className="prompt-placeholder">
            E.g.
            <ul>
                <li>Target audience demographics (age range, cycling experience level, location)</li>
                <li>Content goals and KPIs (educate, solve problems, sales targets)</li>
                <li>Product/service specific details to emphasize</li>
                <li>Research and citation requirements</li>
                <li>Distribution channels and platform requirements</li>
                <li>Content update frequency and seasonal considerations</li>
                <li>Legal and compliance guidelines to follow</li>
                <li>Localization and cultural adaptation needs</li>
                <li>Required sources and expert collaboration</li>
                <li>Competitor content analysis findings</li>
                <li>Image and multimedia specifications</li>
                <li>Internal linking and cross-promotion strategy</li>
                <li>Content promotion and distribution plan</li>
                <li>Success metrics and tracking methods</li>
            </ul>
        </div>
    );
};

export default PromptPlaceholder