import axios from "axios";
import { BASE_URL } from "../common/consts";
//
// const baseEndpoint = "api/projects";
//
// const createBrief = "post-brief";
const getDefaultWritingStylePath = 'api/article/get-writing-styles-options';
const getStyleUsedForGeneratingArticlePath = 'api/article/get-writing-styles';
const saveStyleUsedForGeneratingArticlePath = 'api/article/save-writing-styles';
const saveDefaultStylePath = 'api/project/save-writing-styles'
const generateStyleFromUrlPath = 'api/generate-writing-styles'
const getCampaignStylePath = 'api/project/get-writing-styles'
const getStyleNamePath = 'api/project/get-style-name'
// URL: /api/article/get-writing-styles-options
// METHOD: GET
// PARAMS: fara :)
//
// URL: /api/article/save-writing-styles
// METHOD: POST
// PARAMS: id, writingStyles
//
// URL: /api/project/save-writing-styles
// METHOD: POST
// PARAMS: id, writingStyles
//
// URL: /api/generate-writing-styles
// METHOD: POST
// PARAMS: URL
export const getDefaultWritingStyleRequest = (token) =>
  axios
    .get(`${BASE_URL}/${getDefaultWritingStylePath}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getStyleUsedForGeneratingArticleRequest = (payload, token) =>
  axios
    .post(`${BASE_URL}/${getStyleUsedForGeneratingArticlePath}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


/**
 * param id (article id), writingStyles
 */
export const saveStyleUsedForGeneratingArticleRequest = (payload, token) =>
  axios
    .post(`${BASE_URL}/${saveStyleUsedForGeneratingArticlePath}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));


/**
 * param id (campaign id), writingStyles, url, styleName, styleType
 */
export const saveCampaignStyleRequest = (payload, token) =>
  axios
    .post(`${BASE_URL}/${saveDefaultStylePath}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

/**
 *
 * param url
 */
export const generateStyleFromUrlRequest = (payload, token) =>
  axios
    .post(`${BASE_URL}/${generateStyleFromUrlPath}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data, error } }) => data || {error})
    .catch((error) => Promise.reject(error.response));

/**
 param id (campaign id)
 */
export const getCampaignStyleRequest = (payload, token) =>
  axios
    .post(`${BASE_URL}/${getCampaignStylePath}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));
/**
 param writingStyles
 */
export const getStyleNameRequest = (payload, token) =>
  axios
    .post(`${BASE_URL}/${getStyleNamePath}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

