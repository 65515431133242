import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { setArticleId } from "../../reducers/articles";

import { setTopicCoverageData } from "../../reducers/topicCoverage";

import "./DashboardComponent.css";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import AppLoadingScreen from "../../Shared/AppLoadingScreen";
import ArticlesSidebar from "../ArticlesSidebar/ArticlesSidebar";
import NewArticleBox from "../NewArticleModal/NewArticleBox";

const DasboardComponent = () => {
  const dispatch = useDispatch();
  const [hasArticles, setHasArticles] = useState(null);


  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    dispatch(setKeywordsSearch(null));
  }, []);


  return (
    <>
      {hasArticles === null && <AppLoadingScreen />}
      <div className={`dashboard-page ${hasArticles ? 'dashboard-page--sidebar' : ''}`}>
          <div className="dashboard-page__sidebar">
            <ArticlesSidebar
                open={hasArticles}
                close={false}
                absoluteSidebar={false}
                catchHasArticles={(event) => setHasArticles(event)}
            />
          </div>
        <div className="dashboard-page__content">
          <NewArticleBox noArticles={hasArticles === false} close={false} />
        </div>
      </div>
    </>
  )

};

export default DasboardComponent;
