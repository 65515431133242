import { createSlice } from "@reduxjs/toolkit";
import { processPastedImages } from "../utils/processImagesLimitation";

export const imagesSlice = createSlice({
  name: "images",
  initialState: {
    maxImages: 0,
    currentImages: null,
    limitImagesError: null,
  },
  reducers: {
    setMaxImages: (state, { payload }) => {
      return {
        ...state,
        maxImages: payload,
      };
    },

    setCurrentImages: (state, { payload }) => {
      return {
        ...state,
        currentImages: payload,
      };
    },
    setLimitImagesError: (state, { payload }) => {
      return {
        ...state,
        limitImagesError: payload,
      };
    },
  },
});

// actions
export const { setMaxImages } = imagesSlice.actions;
export const { setCurrentImages } = imagesSlice.actions;
export const { setLimitImagesError } = imagesSlice.actions;

export const checkCurrentImages = () => (dispatch, getState) => {
  const {
    parsedEditor: { totalImages },
  } = getState().texteditor;
  dispatch(setCurrentImages(totalImages));
};

export const transformContentOnPaste = (htmlData) => (dispatch, getState) => {
  const {
    parsedEditor: { totalImages },
  } = getState().texteditor;

  const { html, error } = processPastedImages(htmlData, totalImages);
  dispatch(setLimitImagesError(error));
  return html;
};

// selectors
export const getMaxImages = ({ images: { maxImages } }) => maxImages;

export const getCurrentImages = ({ images: { currentImages } }) =>
  currentImages;

export const getImageLimitError = ({ images: { limitImagesError } }) =>
  limitImagesError;

export const getUpdatedImagesPerc = ({ images: { currentImages, maxImages } }) =>
  ((currentImages * 100) / maxImages) * 2 || 0;

export default imagesSlice.reducer;
